@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

body {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background: #fff;
}

#root {
  min-height: 100vh;
}

.jodit-container {
  margin-top: 0.75rem;
}

.jodit-container h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.jodit-container h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

.jodit-container h4 {
  font-size: 1rem;
  font-weight: 700;
}

.jodit-container h2 {
  font-size: 1.4rem;
  font-weight: 400;
}

.jodit-container li {
  margin-left: 1rem !important;
  font-size: 0.875rem;
  font-weight: 300;
}

.jodit-container p {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
}

.jodit-status-bar,
.jodit-toolbar__box {
  background-color: #fff !important;
}

.cnsozg .inputs .inputWrapper .ck.ck-editor__editable_inline > :last-child,
.bdUyhI .inputs .inputWrapper .ck.ck-editor__editable_inline > :last-child,
.ck.ck-editor__editable_inline > :last-child {
  margin-left: 1rem !important;
}
